
import i18n from '@/vueI18n';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { NewsModel } from '@/modules/news/news-model';

// import { routerAsync } from '@/app-module';

export default {
  name: 'app-related-news-list',
  props: ['id', 'filter'],
  data() {
    return {
      wantedArray: [],
      wantedFilterArray: [],
      counter: 0,
      thumbStyle: {
        // right: "2px",
        borderRadius: '9px',
        backgroundColor: '#A8000F',
        width: '5px',
        opacity: 1,
      },
    };
  },
  methods: {
    ...mapActions({
      doFetch: 'news/list/doFetch',
      setMountedTable: 'news/list/setMountedTable',
      toggleMenu: 'layout/toggleMenu',
      doFetchAds: 'layout/doFetchAds',
      doUpdateViews: 'layout/doUpdateViews',
    }),
    rowPresenter(row, fieldName) {
      // if(!this.is_screen_ipad_pro){
      if (this.isRTL) {
        return row[fieldName]['ar'].slice(0, 60) + '...';
      }
      return row[fieldName]['en'].slice(0, 60) + '...';
      // }
      // else{
      //   if(this.isRTL){
      //       return row[fieldName]['ar'].slice(0,60) + '...'
      //   }
      //   return row[fieldName]['en'].slice(0,60) + '...'
      // }
    },
    presenterTitle(row) {
      if (!this.isRTL) {
        const val = NewsModel.presenter(row, 'title_en');
        return val ? val['en'] : 'ـــــــ';
      } else {
        const val = NewsModel.presenter(row, 'title');
        return val ? val : 'ـــــــ';
      }
    },
    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = NewsModel.presenter(row, fieldName);
      let date = `${now} ${time}`;
      // return moment(date).locale(this.currentLanguageCode).format("hh:mm a");
      return date
        ? moment(date)
            .locale(this.currentLanguageCode)
            .format('dddd D MMM YYYY hh:mm A')
        : 'ـــ';
    },
    displayDate(date) {
      return date
        ? moment(date)
            .locale(this.currentLanguageCode)
            .format('dddd D MMM YYYY hh:mm A')
        : 'ـــ';
      // return date ? moment(date).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ';
    },
    goToArticle(id) {
      console.log(id);
      // routerAsync().push(`/news/${id}`)
      console.log('route', this.$route);
      this.$router.replace({
        name: 'news-view',
        params: { id },
      });
      document.location.reload(false);
      //         .catch(err => {
      //   console.log(err);
      // })
      //          this.$router.replace({
      //   path: '/news',
      //   params:{
      //     id
      //   }
      //   // query: {
      //   //   term: 'lorem',
      //   //   sort: 'alphabetical'
      //   // }
      // })
      // .catch(err => {
      //   console.log(err);
      // })
    },
  },

  computed: {
    isRTL() {
      return i18n.locale == 'ar';
    },
    ...mapGetters({
      is_screen_ipad_pro: 'layout/is_screen_ipad_pro',
      rows: 'news/list/rows',
      ads: 'layout/ads',
      mountedTable: 'news/list/mountedTable',
      loading: 'news/list/loading',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
  },
  async created() {
    this.filter.map((e) => {
      this.wantedFilterArray.push(e.id);
    });
    await this.doFetch({
      filterArray: this.wantedFilterArray,
      action: 'next',
      currentPage: this.paginationPage,
    });
    await this.doFetchAds('articlePage');
    for (let i = 0; i < this.rows.length; i++) {
      if (i % 2 == 0 && this.counter < this.ads.length) {
        this.wantedArray.push(this.ads[this.counter]);
        this.wantedArray.push(this.rows[i]);
        this.counter++;
      } else {
        this.wantedArray.push(this.rows[i]);
      }
    }
    const objWithIdIndex = this.wantedArray.findIndex(
      (obj) => obj.id === this.id,
    );
    this.wantedArray.splice(objWithIdIndex, 1);

    console.log('aaaaaaaa', this.wantedFilterArray);
  },
};
