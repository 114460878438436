
import RelatedNewsList from '@/modules/news/components/related-news-list.vue';
import NewsViewComments from '@/modules/news/components/news-view-comments.vue';
// import commentsSection from '@/modules/news/components/comments-section.vue';

// import { Hooper, Slide,
// Pagination as HooperPagination,
// Navigation as HooperNavigation } from 'hooper';
import 'hooper/dist/hooper.css';
import { mapGetters, mapActions } from 'vuex';
import i18n from '@/vueI18n';
import { NewsModel } from '@/modules/news/news-model';
import moment from 'moment';

export default {
  data() {
    return {
      isLiked: false,
      url:
        'https://www.smouhaclub.com' + this.$route.fullPath,
      viewsCount: 0,
      likesCount: 0,
      slide: 0,
      likedArticle: false,
      isBookmarked: false,
    };
  },
  methods: {
    ...mapActions({
      doFind: 'news/view/doFind',
      doUpdateViews: 'news/view/doUpdateViews',
      doUnlike: 'news/view/doUnlike',
      doFetchLikes: 'news/view/doFetchLikes',
      doFetchUserLike: 'news/view/doFetchUserLike',
      doFetchViewers: 'news/view/doFetchViewers',
      doUpdateLikes: 'news/view/doUpdateLikes',
      setAnonymousToken: 'layout/setAnonymousToken',
    }),
    showMe() {
      console.log(this.record);
    },
    rowPresenter(row, fieldName) {
      if (this.isRTL) {
        return row[fieldName]['ar'];
      }
      return row[fieldName]['en'];
    },
    async doToggleLike(id) {
      if (!this.isLiked) {
        await this.doUpdateLikes({
          id,
          ip: localStorage.getItem('userId'),
        });
        this.likesCount++;
        this.isLiked = !this.isLiked;
      } else {
        await this.doUnlike({
          id,
          ip: localStorage.getItem('userId'),
        });
        this.likesCount--;
        this.isLiked = !this.isLiked;
      }
    },
    doBookmarkArticle() {
      this.isBookmarked = !this.isBookmarked;
    },
    presenterTitle(row) {
      if (!this.isRTL) {
        const val = NewsModel.presenter(row, 'title_en');
        return val ? val['en'] : 'ـــــــ';
      } else {
        const val = NewsModel.presenter(row, 'title');
        return val ? val : 'ـــــــ';
      }
    },
    presenterTime(row, fieldName) {
      //   let now = moment().format('YYYY/MM/DD');
      let date = NewsModel.presenter(row, fieldName);
      //   let date =  `${now} ${time}`;
      // return moment(date).locale(this.currentLanguageCode).format("hh:mm a");
      return date
        ? moment(date)
            .locale(this.currentLanguageCode)
            .format('dddd D MMM YYYY hh:mm A')
        : 'ـــ';
    },
    displayDate(date) {
      return date
        ? moment(date)
            .locale(this.currentLanguageCode)
            .format('dddd D MMM YYYY hh:mm A')
        : 'ـــ';
      // return date ? moment(date).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ';
    },

    async goToRelatedNewsArticle(recordId) {
      console.log('goToRelatedNewsArticle', recordId);

      await this.doFind(recordId);
      // debugger
      this.$router.push({
        params: {
          id: recordId,
        },
      });
    },
  },

  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_ipad_pro: 'layout/is_screen_ipad_pro',
      currentLanguageCode: 'layout/currentLanguageCode',
      record: 'news/view/record',
      loading: 'news/view/loading',
      likes: 'news/view/likes',

      anonymousToken: 'layout/anonymousToken',
      viewsCounter: 'news/view/viewsCounter',
    }),
    isRTL() {
      // return getLanguageCode() == 'ar'
      // return localStorage.getItem('language') == 'ar'
      return i18n.locale == 'ar';
    },
  },
  components: {
    // Hooper,
    // Slide,
    // HooperPagination,
    // HooperNavigation,
    [RelatedNewsList.name]: RelatedNewsList,
    [NewsViewComments.name]: NewsViewComments,
    // commentsSection
  },
  async created() {
    // console.log('params', this.$route.params.id);
    // if (!this.anonymousToken) {
    //   const firebaseUser = await firebase.auth().signInAnonymously();
    //   const token = firebaseUser.user.uid;
    //   console.log('Token: ', token);
    //   this.setAnonymousToken(token);
    // }
    await this.doFind(this.$route.params.id);
    this.viewsCount = this.record.views.length;
    this.likesCount = this.record.likes.length;
    this.isLiked = this.record.likes.find(
      (e) => e == localStorage.getItem('userId'),
    )
      ? true
      : false;
    const myIp = await fetch(
      'https://api.ipify.org?format=json',
    ).then((x) => x.json());

    await this.doUpdateViews({
      id: this.$route.params.id,
      ip: myIp.ip,
    });

    this.viewsCount = this.viewsCounter;
    console.log('sad', this.record);
  },
};
