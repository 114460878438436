
import { mapGetters } from 'vuex'
export default {
  name: 'app-delete-edit-comment',
  methods: {
    openDeleteDialog() {
      console.log('open delete dialog');
    },
    goToEdit() {
      console.log('edit');
    }
  },
  computed: {
    ...mapGetters({
      is_screen_md: 'layout/is_screen_md',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_xs: 'layout/is_screen_xs'
    })

  }
}
