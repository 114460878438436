
import { mapGetters } from 'vuex';
import moment from 'moment';
import DeleteEditComment from '@/modules/news/components/delete-edit-comment.vue';

export default {
  name: 'app-news-view-comments',
  data() {
    return {
      comments: [
        {
          id: 1,
          comment: 'hey',
          createdBy: {
            fullName: "test fullname",
            avatar: "https://lh3.googleusercontent.com/a-/AOh14GggWahhGxCTQuvKk7JpO_uW5cPPLDjV--6xV7dm=s96-c"
          },
          createdAt: {
            seconds: 1633190463,
            nanoseconds: 327000000
          }
        },
        {
          id: 2,
          comment: 'hey dffdsfsd',
          createdBy: {
            fullName: "test fullname",
            avatar: "https://lh3.googleusercontent.com/a-/AOh14GggWahhGxCTQuvKk7JpO_uW5cPPLDjV--6xV7dm=s96-c"
          },
          createdAt: {
            seconds: 1633190463,
            nanoseconds: 327000000
          }
        },
        {
          id: 3,
          comment: 'heyf fgdfdfg ghfghfh',
          createdBy: {
            fullName: "test fullname",
            avatar: "https://lh3.googleusercontent.com/a-/AOh14GggWahhGxCTQuvKk7JpO_uW5cPPLDjV--6xV7dm=s96-c"
          },
          createdAt: {
            seconds: 1633190463,
            nanoseconds: 327000000
          }
        },
        {
          id: 4,
          comment: 'hey',
          createdBy: {
            fullName: "test fullname",
            avatar: null
            // avatar: "https://lh3.googleusercontent.com/a-/AOh14GggWahhGxCTQuvKk7JpO_uW5cPPLDjV--6xV7dm=s96-c"
          },
          createdAt: {
            seconds: 1633190463,
            nanoseconds: 327000000
          }
        },
        {
          id: 5,
          comment: 'heyf fgdfdfg ghfghfh',
          createdBy: {
            fullName: "test fullname",
            avatar: "https://lh3.googleusercontent.com/a-/AOh14GggWahhGxCTQuvKk7JpO_uW5cPPLDjV--6xV7dm=s96-c"
          },
          createdAt: {
            seconds: 1633190463,
            nanoseconds: 327000000
          }
        },
        {
          id: 6,
          comment: 'heyf fgdfdfg ghfghfh',
          createdBy: {
            fullName: "test fullname",
            avatar: "https://lh3.googleusercontent.com/a-/AOh14GggWahhGxCTQuvKk7JpO_uW5cPPLDjV--6xV7dm=s96-c"
          },
          createdAt: {
            seconds: 1633190463,
            nanoseconds: 327000000
          }
        },

      ]
    }
  },
  components: {
    [DeleteEditComment.name]: DeleteEditComment
  },
  computed: {
    ...mapGetters({
      isMobile: "layout/isMobile",
      currentUser: 'auth/currentUser'
      //   record: "article/view/record"
    })
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    async onLoad(index, done) {
      console.log("on load");
      done()
      //   setTimeout(() => {
      //     if (this.recordAfterLast.length > 0) {
      //       this.doFetchMoreComments({ id: this.$route.params.id })
      //       this.newCommentCreated = false

      //     }
      //     done();
      //   }, 2000);
    },
    datePresenterFromNow(date) {
      const language = localStorage.getItem('language')
      moment.locale(language);
      if (moment(date * 1000).fromNow() == 'a few seconds ago') {
        return 'Just Now'
      }
      else {
        return moment(date * 1000).fromNow()
      }
    },
  }
};
